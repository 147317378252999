/* eslint-disable no-console */
import { isRelease } from '~/config';

export default function (ctx) {
  const NAME = 'allow-log';
  const $cookies = ctx.$cookies || ctx.app.$cookies;
  const isAllow = $cookies.get(NAME);
  if (isRelease && !isAllow) {
    // const noop = () => { };
    // console.log = noop;
    // console.error = noop;
    // console.table = noop;
    // console.warn = noop;
    // console.assert = noop;
    // console.clear = noop;
    // console.count = noop;
    // console.countReset = noop;
    // console.debug = noop;
    // console.dir = noop;
    // console.dirxml = noop;
    // console.group = noop;
    // console.groupCollapsed = noop;
    // console.groupEnd = noop;
    // console.time = noop;
    // console.timeEnd = noop;
    // console.timeLog = noop;
    // console.trace = noop;
    // console.profile = noop;
    // console.profileEnd = noop;
    // console.timeStamp = noop;
    // console.markTimeline = noop;
    // console.timeline = noop;
    // console.timelineEnd = noop;
  }
}
